import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyBOVecZHYvE8hsZWcNyyUeKDZTOvP4y-CI',
  authDomain: 'mybook-caminos.firebaseapp.com',
  projectId: 'mybook-caminos',
  storageBucket: 'mybook-caminos.appspot.com',
  messagingSenderId: '864064185725',
  appId: '1:864064185725:web:617774c3bb7c1ae576d814',
  measurementId: 'G-6J2KTDZFZB',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
